import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { PARAM_PAGE, PARAM_SEARCH_QUERY } from '../../../enums/QueryParams'
import useCounter from '../../../hooks/useCounter'
import useCurrentModuleContext from '../../../hooks/useCurrentModuleContext'
import useMapLayer from '../../../hooks/useMapLayer'
import useOLEventListener from '../../../hooks/useOLEventListener'
import useQueryParams from '../../../hooks/useQueryParams'
import { createLogger } from '../../../lib/logger'
import {
  createLabelStyle,
  createSearchResultsStyle,
  LABEL_Z_INDEX,
  MARKER_Z_INDEX
} from '../../../lib/mapStyles'
import useMapContext from '../../map/useMapContext'
import ErrorModal from '../../modals/ErrorModal'
import { SearchContext, SearchContext_, SearchResults_ } from './useSearchContext'
import Feature from 'ol/Feature'
import MapBrowserEvent from 'ol/MapBrowserEvent'
import { Coordinate } from 'ol/coordinate'
import { parseNumber } from '@jalik/form-parser'
import { useThrottledCallback } from '@mantine/hooks'

export const SEARCH_MODULE = 'search'

const logger = createLogger({ name: SEARCH_MODULE })

/**
 * Fournisseur du contexte de recherche.
 * @param props
 */
function SearchContextProvider (props: PropsWithChildren) {
  const { children } = props

  const { setCurrentModule } = useCurrentModuleContext()
  const { map } = useMapContext()
  const [queryParams] = useQueryParams()
  const [error, setError] = useState<Error>(null)
  const [isSearchHelpOpen, setIsSearchHelpOpen] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [searchCenter, setSearchCenter] = useState<Coordinate>(null)
  const [searchPage, setSearchPage] = useState<number>(1)
  const [searchQuery, setSearchQuery] = useState<string>(null)
  const [searchZoom, setSearchZoom] = useState<number>(null)
  const [version, setVersion] = useState<number>(0)

  const setSearchQueryThrottled = useThrottledCallback(setSearchQuery, 100)

  // Définit la couche de recherche.
  const [searchLayer] = useState(() => new VectorLayer({
    source: new VectorSource(),
    style: createSearchResultsStyle(),
    zIndex: MARKER_Z_INDEX
  }))
  useMapLayer(map, searchLayer)

  // Ajoute la couche des libellés.
  const [labelLayer] = useState(() => new VectorLayer({
    // declutter: true,
    source: searchLayer.getSource(),
    style: createLabelStyle,
    zIndex: LABEL_Z_INDEX
  }))
  useMapLayer(map, labelLayer)

  useOLEventListener(searchLayer, 'change:visible', () => {
    labelLayer.setVisible(searchLayer.getVisible())
  })

  const {
    decrement: decrementJobCount,
    increment: incrementJobCount,
    value: jobCount
  } = useCounter(0)

  const {
    increment: incrementResultCount,
    setValue: setResultCount,
    value: resultCount
  } = useCounter(0)

  const {
    increment: incrementSearchCount,
    value: searchCount
  } = useCounter(0)

  /**
   * Ferme la modale d'erreur.
   */
  const closeErrorModal = useCallback(() => {
    setError(null)
  }, [])

  /**
   * Lance une recherche de texte.
   * @param query
   */
  const executeSearch = useCallback((query: string, page = 1) => {
    logger.debug('Execute search', { query })

    // Efface la couche des résultats de recherche.
    searchLayer.getSource().clear()
    searchLayer.setVisible(true)

    // Modifie la requête de recherche.
    setSearchQueryThrottled(query)

    // Enregistre le contexte de recherche.
    setSearchPage(page)
    setSearchCenter(map?.getView()?.getCenter())
    setSearchZoom(map?.getView()?.getZoom())

    setVersion((s) => s + 1)
    incrementSearchCount()

    // Réinitialise le nombre de résultats à chaque nouvelle recherche.
    setResultCount(0)

    // Affiche le panneau des résultats de recherche.
    setCurrentModule(SEARCH_MODULE)
  }, [incrementSearchCount, map, searchLayer, setCurrentModule, setResultCount, setSearchQueryThrottled])

  /**
   * Vérifie si la feature est gérée par le module.
   * @param feature
   */
  const isSearchFeature = useCallback((feature: Feature) => (
    searchLayer.getSource().hasFeature(feature)
  ), [searchLayer])

  /**
   * Vérifie si le clic de carte est géré par le module de recherche.
   */
  const isMapClickEventHandled = useCallback((event: MapBrowserEvent<MouseEvent>) => (
    map.getFeaturesAtPixel(event.pixel).filter(isSearchFeature).length > 0
  ), [map, isSearchFeature])

  /**
   * Gère le lancement d'une recherche.
   */
  const startSearch = useCallback((promise: Promise<SearchResults_>) => {
    // searchLayer.getSource().clear()
    searchLayer.setVisible(true)
    incrementJobCount()
    return promise
      .then((r) => {
        incrementResultCount(r.totalElements)
        return r
      })
      .catch((err: Error) => {
        logger.error(err.message)
        setError(err)
      })
      .finally(() => {
        decrementJobCount()
      })
  }, [decrementJobCount, incrementJobCount, incrementResultCount, searchLayer])

  /**
   * Affiche ou cache l'aide de recherche.
   */
  const toggleSearchHelp = useCallback(() => {
    setIsSearchHelpOpen((s) => !s)
  }, [setIsSearchHelpOpen])

  // Affiche le module lorsqu'une recherche est sélectionnée sur la carte.
  useOLEventListener(map, 'singleclick', (event: MapBrowserEvent<MouseEvent>) => {
    if (isMapClickEventHandled(event)) {
      const clickedFeature = map.getFeaturesAtPixel(event.pixel).shift()

      if (clickedFeature instanceof Feature) {
        setCurrentModule(SEARCH_MODULE)
      }
    }
  })

  // Affiche le panneau de recherche lorsque la recherche est terminée.
  useEffect(() => {
    if (searching) {
      setCurrentModule(SEARCH_MODULE)
    }
  }, [searching, setCurrentModule])

  // Définit l'état de recherche en cours en fonction du nombre de recherches
  // en cours.
  useEffect(() => {
    setSearching(jobCount > 0)
  }, [jobCount, setSearching])

  useEffect(() => {
    if (searchQuery) {
      // Cache le panneau d'aide à la recherche lorsque la recherche est modifiée.
      setIsSearchHelpOpen(false)
    }
  }, [searchQuery])

  // Lance la recherche si la requête est présente dans l'URL.
  const query = queryParams[PARAM_SEARCH_QUERY]
  const page = queryParams[PARAM_PAGE]

  useEffect(() => {
    if (query) {
      executeSearch(query, parseNumber(page))
    }
  }, [executeSearch, page, query])

  const providerContext = useMemo<SearchContext_>(() => ({
    isSearchHelpOpen,
    resultCount,
    searching,
    searchCenter,
    searchCount,
    searchLayer,
    searchPage,
    searchQuery,
    searchZoom,
    setIsSearchHelpOpen,
    setSearching,
    setSearchPage,
    setSearchQuery: executeSearch,
    startSearch,
    toggleSearchHelp,
    version
  }), [
    executeSearch,
    isSearchHelpOpen,
    resultCount,
    searchCenter,
    searchCount,
    searchLayer,
    searchPage,
    searchQuery,
    searchZoom,
    searching,
    setSearchPage,
    startSearch,
    toggleSearchHelp,
    version
  ])

  return (
    <SearchContext.Provider value={providerContext}>
      {children}

      <ErrorModal
        onClose={closeErrorModal}
        opened={error != null}
      >
        {error?.message}
      </ErrorModal>
    </SearchContext.Provider>
  )
}

export default SearchContextProvider
