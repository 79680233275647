**Te Fenua** est une application de visualisation cartographique de l’information publique en
Polynésie française.

<Alert>**Les informations sont exprimées à titre indicatif**.
Les données les plus à jour sont accessibles auprès des services concernés.
Les dates de dernière mise à jour sont indiquées
  dans les informations de la couche.<br/>
En cas de question ou de contestation sur les données affichées, vous pouvez vous adresser au service concerné (contact accessible dans les informations de la couche associée)</Alert>

**Pour en savoir plus sur les cartes**, vous trouverez un bouton _information_ à droite du nom de la
couche. En cliquant dessus, une fenêtre délivre toutes les informations de cette couche.

<div style={{ display: 'none' }}>
  **Vous pouvez participer à leur mise à jour !** Si vous repérez une erreur ou une omission, vous
  pouvez le signaler. Nous vous montrons comment faire dans un court tutoriel dans l’onglet
  _Utilisation_.
</div>

**Si vous avez besoin d’aide**, n’hésitez pas à nous solliciter en décrivant votre problème ou
besoin à cette adresse mail : [exploitation.sig@administration.gov.pf](mailto:exploitation.sig@administration.gov.pf)
